import React, { useEffect, useState } from "react";
import axios from "axios";
import { backEndIP } from "../configuration";
import "./ImportButton.scss";
import CustomAlertBox from "../CustomAlert/CustomAlertBox";

const ImportInventory = ({ userCookie, handleNotifyAlert }) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({
    message: "",
    func: () => {},
  });

  const fetchImageAsBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl, { mode: "cors" });
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios({
        url: `${backEndIP}/category/get_category`,
        method: "GET",
        headers: {
          authorization: userCookie.token,
        },
      });
      const categoryNames = response.data.data.map((item) => item.name);
      setCategories(categoryNames || []);
    } catch (err) {
      console.error("Error fetching categories:", err);
      handleNotifyAlert(
        "notify",
        "Failed to fetch categories. Please try again later.",
        "failed",
        () => {}
      );
    }
  };

  const csvToJson = (csvContent, structure) => {
    try {
      const allRows = csvContent.split("\n").filter((row) => row.trim() !== "");
      const rows = allRows.slice(1);
      const jsonData = rows.map((row) => {
        const values = row.split(",");
        const jsonItem = {};
        //console.log("values", values);
        structure.forEach((key, index) => {
          if (key) jsonItem[key] = values[index]?.trim() || "";
        });

        return jsonItem;
      });
     // console.log("jsonData", jsonData);
      return jsonData;
    } catch (err) {
      console.error("Error parsing CSV file:", err);
      return [];
    }
  };

  const handleFileSelectAndUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      handleNotifyAlert(
        "notify",
        "Please select a CSV file.",
        "failed",
        () => {}
      );
      return;
    }

    const structure = [
      "category",
      "name",
      "quantity",
      "weight",
      "image",
      "description",
    ];
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const csvContent = event.target.result;
        const templateName = csvContent.split("\n")[0].split(",")[0];
        //console.log("TEMPLATE", templateName);
        if (templateName != "ADD_INVENTORY_TEMPLATE") {
          //console.log("ERRORRR");
          handleNotifyAlert(
            "notify",
            "Please select the correct CSV file.",
            "failed",
            () => {}
          );
          return;
        }
        const templateName1 = csvContent.split("\n").slice(1).join("\n");
       // console.log("csvContent", csvContent);
        //console.log("TEMPLATE1", templateName1);
        const jsonData = csvToJson(templateName1, structure);

        const validData = [];
        const invalidRows = [];
        //const invalidCatRows= [];
        jsonData.forEach((row, index) => {
          const isRowValid = structure.every((key) => {
            if (key !== "image") {
              return row[key] && row[key].trim() !== "";
            } else return true;
          });
          const categoryExists = categories.includes(row.category);

          if (!isRowValid) {
            invalidRows.push(index + 1);
          } else if (!categoryExists) {
            invalidRows.push(index + 1);
          } else {
            validData.push(row);
          }
        });

        if (invalidRows.length > 0) {
          // const message = Rows have invalid data.;

          // // if(invalidCatRows.length > 0){
          // //     message += \n Rows ${invalidRows.join(', ')} have invalid category.
          // // }
          // // const message = invalidRows
          // //     .map((row) => Row ${row.index}: ${row.reason})
          // //     .join('\n');

          // setModalMessage({
          //     message: ${message}\nContinue with valid rows?,
          //     func: async () => {
          //         for (const item of validData) {
          //             try {
          //                 const base64Image = item.image
          //                     ? await fetchImageAsBase64(item.image)
          //                     : 'default_medicine';

          //                 const inventoryItem = {
          //                     name: item.name,
          //                     filedata: base64Image,
          //                     description: item.description,
          //                     available_quantity: parseInt(item.quantity, 10),
          //                     weight: parseFloat(item.weight),
          //                     v_weight: parseFloat(item.weight),
          //                     price: 0,
          //                     location: '',
          //                     category: item.category,
          //                 };

          //                 await uploadToBackend(inventoryItem);
          //             } catch (error) {
          //                 console.error(Failed to process item ${item.name}:, error);
          //             }
          //         }
          //         setOpenModal(false);
          //     },
          // });
          // setOpenModal(true);
          handleNotifyAlert(
            "notify",
            "Please ensure all categories are valid and all fields are completed.",
            "failed",
            () => {}
          );
          return;
        } else {
          for (const item of validData) {
            const base64Image = item.image
              ? await fetchImageAsBase64(item.image)
              : "default_medicine";

            const inventoryItem = {
              name: item.name,
              filedata: base64Image,
              description: item.description,
              available_quantity: parseInt(item.quantity, 10),
              weight: parseFloat(item.weight),
              v_weight: parseFloat(item.weight),
              price: 0,
              location: "",
              category: item.category,
            };

            await uploadToBackend(inventoryItem);
          }
        }

        e.target.value = "";
      } catch (err) {
        console.error("Error processing file:", err);
        handleNotifyAlert(
          "notify",
          "Error processing the file. Please ensure it is formatted correctly.",
          "failed",
          () => {}
        );
      }
    };

    reader.readAsText(file);
  };

  const uploadToBackend = async (item) => {
    try {
      setLoading(true);
      await axios({
        url: `${backEndIP}/inventory/add-item-inventory`,
        method: "POST",
        headers: {
          authorization: userCookie.token,
        },
        data: {
          ...item,
        },
      });
      handleNotifyAlert(
        "notify",
        ` Item "${item.name}" added to Inventory successfully`,
        "success",
        () => {}
      );
    } catch (err) {
      handleNotifyAlert(
        "notify",
        `Failed to add item "${item.name}": ${err.response?.data?.message || "Unknown error"}`,
        "failed",
        () => {}
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <div className="Import-btn-container">
      <label htmlFor="file-upload" style={{ display: "none" }}>
        Upload CSV
      </label>
      <input
        id="file-upload"
        type="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={handleFileSelectAndUpload}
      />
      <button
        className="import-button"
        onClick={() => document.getElementById("file-upload").click()}
        disabled={loading}
      >
        {loading ? "Uploading..." : "Import CSV"}
      </button>

      {openModal && (
        <CustomAlertBox
          setOpenModal={setOpenModal}
          messageToDisplay={modalMessage}
        />
      )}
    </div>
  );
};

export default ImportInventory;

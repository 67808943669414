import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { backEndIP } from '../configuration';
import './ImportButton.scss';

const ImportCategory = ({ userCookie, handleNotifyAlert }) => {
    const [loading, setLoading] = useState(false);
    
    const handleFileSelectAndUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            handleNotifyAlert('notify', 'Please select a CSV file.', 'failed', () => {});
            return;
        }
        const reader = new FileReader();
        reader.onload = async (event) => {
            try {
                const csvContent = event.target.result;
                const templateName = csvContent.split('\n')[0].split(',')[0];
                //console.log("TEMPLATE", templateName);
                if(templateName!="ADD_CATEGORY_TEMPLATE"){
                   // console.log("ERRORRR")
                    handleNotifyAlert('notify', 'Please select the correct CSV file.', 'failed', () =>{})
                    return;
                }
                //console.log("Skiped the IF")
                let allCategory = csvContent.split('\n').slice(2)
                const categoryObj = {}
                allCategory.forEach(cate => {
                    categoryObj[cate]=""
                });
                allCategory =  Object.keys(categoryObj)
                for (const item of allCategory) {
                    const data = item.split(',');
                    if(data[0].trim()!==''){
                    //console.log("DATA", data[0])
                    await uploadToBackend(data[0]);}
                }

                e.target.value = '';
            } catch (err) {
                console.error('Error processing file:', err);
                handleNotifyAlert(
                    'notify',
                    'Error processing the file. Please ensure it is formatted correctly.',
                    'failed',
                    () => {}
                );
            }
        };

        reader.readAsText(file);
    };

    const uploadToBackend = async (item) => {
        try {
            //console.log("ENTER UPLOAD", item)
            setLoading(true);
            axios({
                url: backEndIP + "/category/add_category",
                method: "POST",
                headers: {
                  authorization: userCookie.token,
                },
                data: {
                  name: item,
                },
                
              }).catch((err)=>{
                console.log(err?.response)
              });
            
            handleNotifyAlert(
                'notify',
                `Category Added successfully`,
                'success',
                () => {}
            );
        } catch (err) {
            handleNotifyAlert(
                'notify',
                `Failed to add Category "${item}": ${err?.response?.data?.message || 'Unknown error'}`,
                'failed',
                () => {}
            );
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='Import-btn-container'>
            <label htmlFor="file-upload" style={{ display: 'none' }}>
                Upload CSV
            </label>
            <input
                id="file-upload"
                type="file"
                accept=".csv"
                style={{ display: 'none' }}
                onChange={handleFileSelectAndUpload}
            />
            <button
                className="import-button"
                onClick={() => document.getElementById('file-upload').click()}
                disabled={loading}
            >
                {loading ? 'Uploading...' : 'Import CSV'}
            </button>
        </div>
    );
};

export default ImportCategory;
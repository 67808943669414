import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { backEndIP } from "../configuration";
import "./viewAllOrders.scss";
import dayjs from "dayjs";
import OrderDetailsPopup from "../OrderDetailsPopUp/OrderDetailsPopUp";
import OrderDetailsTab from "../OrderDetailsTab/OrderDetailsTab";
import { useNavigate } from "react-router-dom";
import ManualOrderTable from "../ViewAllManualOrders/ViewAllManualOrders";
import { CustomerDetailContext } from "../../context/CustomerDetailContext";

const OrdersTabel = ({
    userCookie,
    SetLoadingVisibility,
    handleNotifyAlert,
    setSelectedSubContent,
    setContentOption,
    setOrderNo,
    setSelectedTab
}) => {
    const navigate = useNavigate()
    const [orderListItems, setOrderListItems] = useState([]);
    const [orderListItemCopy, setOrderListItemCopy] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    const [filterBy, setFilterBy] = useState("ALL")
    const [sortSetting, setSortSetting] = useState(["", "order_no", "dsc"]);
    const [duration, setDuration] = useState({ d: "Last 3 days" })
    const [index, setIndex] = useState(0)
    const [updateSelf, setUpdateSelf] = useState(false);
    const [locations, setLocations] = useState([])
    const { selectOrderView, setSelectOrderView } = useContext(CustomerDetailContext)
    const DurationOptions = {
        "Today": dayjs().subtract(1, "day").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 3 days": dayjs().subtract(3, "day").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 1 week": dayjs().subtract(1, "week").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 2 weeks": dayjs().subtract(2, "week").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 1 Month": dayjs().subtract(1, "month").set('hour', 0).set('minute', 0).set('second', 0),
        "Last 3 Months": dayjs().subtract(3, "month").set('hour', 0).set('minute', 0).set('second', 0),
    }
    useEffect(() => {
        SetLoadingVisibility(true)
        const getAllDataWithOrder = async () => {
            axios({
                url: backEndIP + "/order/get_allOrders_inventoryManager",
                method: "GET",
                headers: {
                    authorization: userCookie.token,
                },
            }).then((data) => {
                SetLoadingVisibility(false)
                //console.log(data.data);
                setOrderListItems(data.data.data);
                setOrderListItemCopy(data.data.data)
            }).catch((err) => {
                //console.log(err);
                SetLoadingVisibility(false);
                handleNotifyAlert(
                    "notify",
                    `${err.response.data.message}`,
                    "failed",
                    () => { }
                );
            })
            const respAllLocations = await axios({
                url: backEndIP + "/hub/get_allLocation",
                method: "GET",
                headers: {
                    authorization: userCookie.token,
                },
            }).catch((err) => {
                SetLoadingVisibility(false);
                handleNotifyAlert(
                    "notify",
                    `${err.response.data.message}`,
                    "failed",
                    () => { }
                );
            })
            setLocations(respAllLocations.data.data)
        }
        getAllDataWithOrder()
    }, []);


    // console.log("location", locations)



    const orderStatus = {
        1: {
            key: "placed",
            name: "Placed"
        },
        2: {
            key: "approved",
            name: "Approved"
        },
        3: {
            key: "packed",
            name: "Packed"
        },
        4: {
            key: "sent_from_hub",
            name: "Sent From Hub"
        },
        5: {
            key: "intransit",
            name: "In Transit"
        },
        6: {
            key: "recieved_at_local_station",
            name: "Recieved At Local Station"
        },
        7: {
            key: "sent_out_delivery",
            name: "Sent Out Delivery"
        },
        8: {
            key: "delivered",
            name: "Delivered"
        },
        9: {
            key: "canceled",
            name: "Canceled"
        }
    }
    const handelPopUp = (items, i) => {
        setCurrentItem(items)
        setIndex(i)
        setShowPopup(true)
    }

    const updateStatus = (item) => {
        const Id = item._id;
        const statusNum = item.current_status.number + 1;
        //const getNewData = updateStatus(item)
        const dataTobeUpdated = ({ order_id: Id, statusNumber: statusNum, statusName: orderStatus[statusNum].name })
        return dataTobeUpdated
    }

    const handelStatusUpdate = async (e, item, i) => {
        //console.log(item)
        const getNewData = await updateStatus(item)
        //console.log(getNewData)
        if (getNewData !== undefined) {
            SetLoadingVisibility(true)
            await axios({
                url: backEndIP + "/order/update_order_status",
                method: "PUT",
                headers: {
                    authorization: userCookie.token,
                },
                data: {
                    order_id: getNewData.order_id,
                    statusNumber: getNewData.statusNumber,
                    statusName: getNewData.statusName
                }
            }).then((resp) => {
                SetLoadingVisibility(false)
                // console.log(resp);
                // orderListItems[i] = resp.data.data
                updateorderListItemCopyData(resp.data.data)
                //  orderListItemCopy[i] = resp.data.data
                // setOrderListItems([...orderListItems]);
                setOrderListItemCopy([...orderListItemCopy])

            }).catch((err) => {
                // console.log(err);
                SetLoadingVisibility(false);
                handleNotifyAlert(
                    "notify",
                    `${err.response.data.message}`,
                    "failed",
                    () => { }
                );
            })
        } else {
            SetLoadingVisibility(false);
            handleNotifyAlert(
                "notify",
                `Please Provide Correct Data`,
                "failed",
                () => { }
            );
        }

        console.log(orderListItemCopy)
    }
    //console.log(orderStatusUpdateData)
    const getTodaysData = () => {
        let verifiedData = []
        // console.log(orderListItemCopy)
        orderListItemCopy.forEach((item) => {
            // console.log(dayjs(item.placed))
            // console.log(dayjs(DurationOptions[duration.d]))
            if (dayjs(item.placed) >= dayjs(DurationOptions[duration.d])) {
                verifiedData.push(item);
            }
        })
        // console.log(verifiedData)
        return verifiedData
    }
    useEffect(() => {
        const durationFilteredOrders = getTodaysData();
        const verifiedData = handleFilterChange(durationFilteredOrders)
        // console.log(verifiedData)
        setOrderListItems(verifiedData)
    }, [orderListItemCopy, filterBy, duration.d])

    const handelFilterByDuration = (e) => {
        //console.log(e.target.value)
        //console.log(DurationOptions[e.target.value])
        setDuration({ d: e.target.value })
        let verifiedData = [];
        orderListItems.forEach((item) => {
            // console.log(item.placed)
            if (dayjs(item.placed) >= dayjs(DurationOptions[e.target.value])) {
                verifiedData.push(item)
            }
        })
        setOrderListItems(verifiedData)
    }

    const updateorderListItemCopyData = (order) => {
        for (let i = 0; i <= orderListItemCopy.length - 1; i++) {
            if (orderListItemCopy[i].order_no === order.order_no) {
                orderListItemCopy[i] = order;
                break
            }
        }
        setOrderListItemCopy([...orderListItemCopy])
    }

    //console.log(DurationOptions[duration.d])

    const handleFilterChange = (ordersArr) => {
        // console.log(e.currentTarget.innerHTML)
        let verifiedData = []
        //console.log(duration)
        if (filterBy === "ALL") {
            ordersArr.forEach((item) => {
                if (dayjs(item.placed) >= dayjs(DurationOptions[duration.d])) {
                    verifiedData.push(item)
                }
            })
            // verifiedData = [...ordersArr]

        } else if (filterBy === "Open") {
            verifiedData = []
            ordersArr.forEach((item) => {
                if (dayjs(item.placed) >= dayjs(DurationOptions[duration.d])) {
                    if (item.current_status.name !== "Delivered" && item.current_status.name !== "Canceled") {

                        verifiedData.push(item)
                    }
                    // verifiedData.push(item)
                }
            })
        } else if (filterBy === "Delivered") {
            verifiedData = []
            ordersArr.forEach((item) => {
                if (dayjs(item.placed) >= dayjs(DurationOptions[duration.d])) {
                    if (item.current_status.name === "Delivered") {

                        verifiedData.push(item)
                    }
                }
            })
        }
        else if (filterBy === "Canceled") {
            verifiedData = []
            ordersArr.forEach((item) => {
                if (dayjs(item.placed) >= dayjs(DurationOptions[duration.d])) {
                    if (item.current_status.name === "Canceled") {

                        verifiedData.push(item)
                    }
                }
            })
        }
        //console.log(verifiedData)
        return verifiedData
    }

    //setFilterBy(e.currentTarget.innerHTML);
    // e.currentTarget.

    const sortRows = (Data, sortType = 0, key, sortBy) => {
        // console.log(sortBy, key)
        const sortAsc = (a, b) => {
            // console.log(a[key], b[key], key)
            if (a[key] < b[key]) {
                return -1;
            }
            else if (a[key] > b[key]) {
                return 1;
            }
            else {
                return 0;
            }
        }
        const sortDsc = (a, b) => {
            // console.log(a[key], b[key], key)
            if (a[key] < b[key]) {
                return 1;
            }
            else if (a[key] > b[key]) {
                return -1;
            }
            else {
                return 0;
            }
        }

        const sortAscLoc = (a, b) => {
            // console.log(locations[a[key]], locations[b[key]], key)
            if (locations[a[key]] < locations[b[key]]) {
                return -1;
            }
            else if (locations[a[key]] > locations[b[key]]) {
                return 1;
            }
            else {
                return 0;
            }
        }
        const sortDscLoc = (a, b) => {
            // console.log(locations[a[key]], locations[b[key]], key)
            if (locations[a[key]] < locations[b[key]]) {
                return 1;
            }
            else if (locations[a[key]] > locations[b[key]]) {
                return -1;
            }
            else {
                return 0;
            }
        }
        const sortNumberDsc = ((a, b) => {
            if (a.current_status.number < b.current_status.number) {
                return 1;
            }
            else if (a.current_status.number > b.current_status.number) {
                return -1;
            }
            else {
                return 0;
            }
        })
        const sortNumberAsc = ((a, b) => {
            if (a.current_status.number < b.current_status.number) {
                return -1;
            }
            else if (a.current_status.number > b.current_status.number) {
                return 1;
            }
            else {
                return 0;
            }
        })

        if (sortBy === "dsc") {
            // console.log("dcs")
            if (sortType === "loc")
                Data.sort(sortDscLoc);
            else if (sortType === "number")
                Data.sort(sortNumberDsc);
            else
                Data.sort(sortDsc);
            // console.log(dat)
        }
        else {
            if (sortType === "loc")
                Data.sort(sortAscLoc);
            else if (sortType === "number")
                Data.sort(sortNumberAsc);
            else
                Data.sort(sortAsc);
            // console.log(dat)
        }
        return Data
    }
    const handelMyOrderButton = () => {
        if (userCookie.token === "" || !userCookie.token) {
            navigate("/");
            return;
        }
        SetLoadingVisibility(true);
        axios({
            url: backEndIP + "/order/orders-list",
            method: "GET",
            headers: {
                authorization: userCookie.token,
            },
            // data: { id: product._id , quantity:1 }
        })
            .then((resp) => {
                //console.log(resp.data);
                SetLoadingVisibility(false);
                setOrderListItems(resp.data.data.order)
                //   setOrdersList(resp.data.data.order);
            })
            .catch((err) => {
                SetLoadingVisibility(false);
                if (err.response.data.message) window.alert(err.response.data.message);
                else window.alert("some error occurered");
                //console.log(err);
            });
    }

    useEffect(() => {
        //  sortRows(allOrders,...sortSetting)
        const data = sortRows(orderListItems, ...sortSetting)
        //console.log("sorting",sortSetting,data )
        setUpdateSelf(prev => !prev)
    }, [sortSetting[0], sortSetting[1], sortSetting[2], orderListItems])

    return (
        <div className="OrderTable-container">
            <div className="OrderTable">
                <div className="OrderTable__title"></div>
                <div className="OrderTable__header">
                    <button
                        className={`${selectOrderView === "OMS" ? "OrderTable__header__linksFocus" : "OrderTable__header__links"}`}
                        onClick={() => setSelectOrderView("OMS")}
                    >
                        Orders (OMS)
                    </button>
                    {/* <button
                        className="OrderTable__header__link1"
                        onClick={() => setSelectOrderView("Inventory List")}
                    >
                        Inventory List
                    </button> */}
                    <button className={`${selectOrderView === "Manual Orders" ? "OrderTable__header__linkFocus" : "OrderTable__header__link"}`}
                        onClick={() => setSelectOrderView("Manual Orders")}>
                        Manual Orders
                    </button>
                </div>
            </div>
            {selectOrderView === "OMS" ? (<>
                <div className='OrderTable-container__filter'>
                    <div className='OrderTable-container__filter__title'>Filter by : </div>
                    <div className='OrderTable-container__filter__options'>
                        <button className='OrderTable-container__filter__options__item'
                            style={{ backgroundColor: filterBy === "ALL" ? "#FD6125" : null, color: filterBy === "ALL" ? "white" : null }}
                            onClick={(e) => { setFilterBy("ALL") }}>ALL</button>
                        <button className='OrderTable-container__filter__options__item__open'
                            style={{ backgroundColor: filterBy === "Open" ? "#F2D058" : null, color: filterBy === "Open" ? "white" : null }}
                            onClick={(e) => { setFilterBy("Open") }}>Open</button>
                        <button className='OrderTable-container__filter__options__item__delivered'
                            style={{ backgroundColor: filterBy === "Delivered" ? "#6CCC54" : null, color: filterBy === "Delivered" ? "white" : null }}
                            onClick={(e) => { setFilterBy("Delivered") }}>Delivered</button>
                        <button className='OrderTable-container__filter__options__item__cancelled'
                            style={{ backgroundColor: filterBy === "Canceled" ? "#F25858" : null, color: filterBy === "Canceled" ? "white" : null }}
                            onClick={(e) => { setFilterBy("Canceled") }}>Canceled</button>
                    </div>
                    <div className='OrderTable-container__filter__duration-title'>Duration :</div>
                    <select className='OrderTable-container__filter__duration-select'
                        defaultValue={duration.d}
                        onChange={(e) => {
                            handelFilterByDuration(e)
                        }}>
                        {/* <option className='OrderTable-container__filter__duration-select__option'>{duration.d}</option> */}
                        <option className='OrderTable-container__filter__duration-select__option'>Today</option>
                        <option className='OrderTable-container__filter__duration-select__option'>Last 3 days</option>
                        <option className='OrderTable-container__filter__duration-select__option'>Last 1 week</option>
                        <option className='OrderTable-container__filter__duration-select__option'>Last 2 weeks</option>
                        <option className='OrderTable-container__filter__duration-select__option'>Last 1 Month</option>
                        <option className='OrderTable-container__filter__duration-select__option'>Last 3 Months</option>
                    </select>
                    {/* <div className="OrderTable-container__filter__orderButton" onClick={handelMyOrderButton}>
                    <div>My Orders</div>
                </div> */}
                </div>
                <div className="OrderTable-container__heading">
                    <div className="OrderTable-container__heading__tabel">
                        <div className="OrderTable-container__heading__tabel__itemStart__serialno" >#</div>
                        <div className="OrderTable-container__heading__tabel__item__orderNo" onClick={() => {
                            // setFocus(true)
                            if (sortSetting[1] === "order_no" && sortSetting[2] === "asc") {
                                //sortRows(orderListItems, "dsc", "order_no",);
                                setSortSetting(["", "order_no", "dsc"])
                            }
                            else {
                                //sortRows(orderListItems, "asc", "order_no",);
                                setSortSetting(["", "order_no", "asc"])
                            }
                        }} >Order ID </div>
                        <div className="OrderTable-container__heading__tabel__item__noItem" onClick={() => {
                            if (sortSetting[1] === "cart" && sortSetting[2] === "asc") {
                                //sortRows(orderListItems, "dsc", "cart",);
                                setSortSetting(["", "cart", "dsc"])
                            }
                            else {
                                //sortRows(orderListItems, "asc", "cart",);
                                setSortSetting(["", "cart", "asc"])
                            }
                        }}>Products</div>
                        <div className="OrderTable-container__heading__tabel__item__customer" onClick={() => {
                            if (sortSetting[1] === "useremail" && sortSetting[2] === "asc") {
                                //sortRows(orderListItems, "dsc", "useremail",);
                                setSortSetting(["", "useremail", "asc"])
                            }
                            else {
                                //sortRows(orderListItems, "asc", "useremail",);
                                setSortSetting(["", "useremail", "asc"])
                            }
                        }}>Customer Name</div>
                        <div className="OrderTable-container__heading__tabel__item__weight" onClick={() => {
                            if (sortSetting[1] === "total_weight" && sortSetting[2] === "asc") {
                                //sortRows(orderListItems, "dsc", "total_weight",);
                                setSortSetting(["", "total_weight", "dsc"])
                            }
                            else {
                                //sortRows(orderListItems, "asc", "total_weight",);
                                setSortSetting(["", "total_weight", "asc"])
                            }
                        }}>Weight</div>
                        <div className="OrderTable-container__heading__tabel__item__quantity" onClick={() => {
                            if (sortSetting[1] === "total_quantity" && setSortSetting[2] === "asc") {
                                //sortRows(orderListItems, "dsc", "total_quantity",);
                                setSortSetting(["", "total_quantity", "dsc"])
                            }
                            else {
                                //sortRows(orderListItems, "asc", "total_quantity",);
                                setSortSetting(["", "total_quantity", "asc"])
                            }
                        }}>Qty</div>
                        <div className="OrderTable-container__heading__tabel__item__startLoc" onClick={() => {
                            if (sortSetting[1] === "source_location" && sortSetting[2] === "asc") {
                                //sortRows(orderListItems, "loc", "source_location",);
                                setSortSetting(["loc", "source_location", "dsc"])
                            }
                            else {
                                //sortRows(orderListItems, "loc", "source_location",);
                                setSortSetting(["loc", "source_location", "asc"])
                            }
                        }}>Source</div>
                        <div className="OrderTable-container__heading__tabel__item__endLoc" onClick={() => {
                            if (sortSetting[1] === "destination_location" && sortSetting[2] === "asc") {
                                //sortRows(orderListItems, "loc", "destination_location",);
                                setSortSetting(["loc", "destination_location", "dsc"])
                            }
                            else {
                                //sortRows(orderListItems, "loc", "destination_location",);
                                setSortSetting(["loc", "destination_location", "asc"])
                            }
                        }}>Delivery Location</div>
                        <div className="OrderTable-container__heading__tabel__item__status" onClick={() => {
                            if (sortSetting[1] === "current_status.number" && sortSetting[2] === "asc") {
                                // sortRows(allOrders, "dsc", "current_status.number",)

                                setSortSetting(["number", "current_status.number", "dsc"]);
                            }
                            else {
                                // sortRows(allOrders, "asc", "current_status.number",);
                                setSortSetting(["number", "current_status.number", "asc"]);
                            }
                        }}>Current Status</div>
                        <div className="OrderTable-container__heading__tabel__item__orderDate" onClick={() => {
                            if (sortSetting[1] === "placed" && sortSetting[2] === "asc") {
                                //sortRows(orderListItems, "dsc", "placed",);
                                setSortSetting(["", "placed", "dsc"])
                            }
                            else {
                                //sortRows(orderListItems, "asc", "placed",);
                                setSortSetting(["", "placed", "asc"])
                            }
                        }}>Placed Date</div>
                        <div className="OrderTable-container__heading__tabel__item__deliveredD" onClick={() => {
                            if (sortSetting[1] === "delivery_date" && sortSetting[2] === "asc") {
                                //sortRows(orderListItems, "dsc", "delivery_date",);
                                setSortSetting(["", "delivery_date", "dsc"])
                            }
                            else {
                                // sortRows(orderListItems, "asc", "delivery_date",);
                                setSortSetting(["", "delivery_date", "asc"])
                            }
                        }}>Delivery Date</div>
                        <div className="OrderTable-container__heading__tabel__itemEnd__track">Action</div>
                        {/* <div style={{ width: "150px" }} className="InventoryTable__heading__tabel__item">Update</div>
          <div style={{ width: "100px" }} className="InventoryTable__heading__tabel__item">Delete</div> */}
                    </div>


                </div>
                <div className="OrderTable-container__heading__rows">
                    {orderListItems.map((orderItem, i) => {
                        return (
                            //  <div className="InventoryTable__heading__rows__itemData">
                            <div className="OrderTable-container__heading__rows__itemRow" key={i + 1}>
                                <div className="OrderTable-container__heading__rows__itemRow__itemStart__serialno">{i + 1}</div>
                                <div className="OrderTable-container__heading__rows__itemRow__item__orderNo">{orderItem.order_id}</div>
                                <div className="OrderTable-container__heading__rows__itemRow__item__noItem">{orderItem.cart.length}</div>
                                <div className="OrderTable-container__heading__rows__itemRow__item__customer">{orderItem.useremail.split("@")[0]}</div>
                                <div className="OrderTable-container__heading__rows__itemRow__item__weight">{orderItem.total_weight}</div>
                                <div className="OrderTable-container__heading__rows__itemRow__item__quantity">{orderItem.total_quantity}</div>
                                {locations.map((startLoc) => {
                                    if (startLoc._id === orderItem.source_location) {
                                        return <div className="OrderTable-container__heading__rows__itemRow__item__startLoc">{startLoc.location_name}</div>
                                    }
                                })}
                                {locations.map((startLoc) => {
                                    if (startLoc._id === orderItem.destination_location) {
                                        return <div className="OrderTable-container__heading__rows__itemRow__item__endLoc">{startLoc.location_name}</div>
                                    }
                                })}
                                <div className={`OrderTable-container__heading__rows__itemRow__item__status__colorCode${orderItem.current_status.number}`}>{orderItem.current_status.name}
                                    {orderItem.current_status.number <= 2 ? <button className={`OrderTable-container__heading__rows__itemRow__item__status__colorCode${orderItem.current_status.number}__updateBtn`} onClick={(e) => {
                                        handelStatusUpdate(e, orderItem, i)
                                    }}>Update Status</button> : null}</div>
                                {/* <div className="OrderTable-container__heading__rows__itemRow__item__deliverTime">{orderItem.delivery_type}</div> */}
                                <div className="OrderTable-container__heading__rows__itemRow__item__orderDate">{dayjs(orderItem.placed).format("MMM D, YYYY h:mm A").toString()}</div>
                                <div className="OrderTable-container__heading__rows__itemRow__item__deliveredD">{orderItem.current_status.number === 9 ? <div style={{ color: "red" }}>Canceled</div> : <>{orderItem.current_status.number < 8 ? dayjs(orderItem.delivery_date).format("MMM D, YYYY h:mm A").toString() : dayjs(orderItem.delivered).format("MMM D, YYYY h:mm A").toString()}</>}</div>
                                <div className="OrderTable-container__heading__rows__itemRow__itemEnd__getDetails"><button className="OrderTable-container__heading__rows__itemRow__itemEnd__getDetails__getBtn" onClick={() => {
                                    handelPopUp(orderItem, i)
                                }}>Details</button></div>

                                <div className="OrderTable-container__heading__rows__itemRow__itemEnd__track"><button className="OrderTable-container__heading__rows__itemRow__itemEnd__track__trackBtn" onClick={() => {
                                    console.log("orderItem.order_no", orderItem.order_id)
                                   setOrderNo(orderItem.order_id);
                                   setContentOption("SpecficOrder")
                                   // navigate(`/order/${resp.data.order_no}`)
                                   setSelectedSubContent("SpecficOrder")
                                   setSelectedTab("Manage Orders")
                                }}>Track</button></div>
                                {/* <div style={{ width: "150px" }} className="InventoryTable__heading__rows__itemRow__item"><button className="InventoryTable__heading__rows__itemRow__item__updateButton" onClick={() => handleUpdate(item)}>Edit</button></div>
              <div style={{ width: "100px" }} className="InventoryTable__heading__rows__itemRow__itemEnd">
                <div className="InventoryTable__heading__rows__itemRow__itemEnd__delete" onClick={() => handleDelete(item)}><span class="material-symbols-outlined" style={{fontSize:"calc(28px * var(--heightmultiplier))"}}>delete</span></div>
              </div> */}
                            </div>
                            // </div>
                        )
                    })}
                </div></>) : <ManualOrderTable
                userCookie={userCookie}
                SetLoadingVisibility={SetLoadingVisibility}
                handleNotifyAlert={handleNotifyAlert}
                setSelectedSubContent={setSelectedSubContent}
                setContentOption={setContentOption}
                setOrderNo={setOrderNo}
                setSelectedTab={setSelectedTab}
            />}
            {showPopup &&
                <OrderDetailsPopup
                    setOpenModal={setShowPopup}
                    messageToDisplay={
                        {
                            message: <OrderDetailsTab
                                props={currentItem}
                                setShowPopup={setShowPopup}
                                userCookie={userCookie}
                                SetLoadingVisibility={SetLoadingVisibility}
                                setUpdateSelf={setUpdateSelf}
                                handleNotifyAlert={handleNotifyAlert}
                                orderListItemCopy={orderListItemCopy}
                                orderListItems={orderListItems}
                                setOrderListItemCopy={setOrderListItemCopy}
                                setOrderListItems={setOrderListItems}
                                updateOrderDetailsData={updateorderListItemCopyData}
                                index={index} locations={locations} />, func: () => { }
                        }} />}
        </div>
    );
};

export default OrdersTabel;
//() => handleDelete(item._id)
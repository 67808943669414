import React, { useEffect } from 'react'
import LeftPanelTabLabels from './LeftPanelTabLabels'
import { useNavigate } from 'react-router-dom'
import "./LeftPanelHomePage.scss"
import ArowBackIcon from "@rsuite/icons/ArowBack"
import "rsuite/dist/rsuite.min.css";
import { backEndIP } from '../../configuration'
// import { logoutUser } from '../../../Actions/AuthAction'
// import LogoutConfirmPopup from '../../PopupComponent/LogoutConfirmPopUp/LogoutConfirmPopUp'

const teLogo = <svg xmlns="http://www.w3.org/2000/svg" width="131" height="17" viewBox="0 0 131 17" fill="none">
  <path d="M104.618 12.0419C104.618 13.5098 104.598 14.9793 104.63 16.4473C104.641 16.8956 104.488 16.9706 104.083 16.9676C101.702 16.9496 99.3224 16.9811 96.9438 16.9526C94.2092 16.9196 91.9845 15.4531 91.1791 12.8921C90.253 9.94866 90.1353 6.98426 91.2967 4.06634C92.256 1.65373 94.2183 0.317722 96.8789 0.26824C99.3209 0.223257 101.763 0.260741 104.205 0.247246C104.52 0.245746 104.639 0.314721 104.627 0.650597C104.601 1.34484 104.601 2.04209 104.627 2.73633C104.639 3.06771 104.531 3.14568 104.211 3.14268C102.126 3.12919 100.04 3.18466 97.9589 3.12169C95.9408 3.06021 94.7492 4.11582 94.3646 5.95114C93.9468 7.9409 93.9015 9.93217 94.5501 11.8859C94.989 13.2085 95.9589 13.8832 97.3495 13.8967C98.5622 13.9087 99.7764 13.8907 100.989 13.9087C101.283 13.9132 101.356 13.8217 101.351 13.5413C101.336 12.4902 101.336 11.4391 101.351 10.388C101.356 10.0956 101.286 9.98914 100.966 9.99364C99.8156 10.0146 98.6648 9.98914 97.5139 10.0086C97.1851 10.0146 97.0524 9.95916 97.069 9.58879C97.1022 8.84807 97.0871 8.10584 97.075 7.36361C97.0705 7.1267 97.1368 7.04273 97.3872 7.04423C99.6889 7.05323 101.992 7.05473 104.294 7.04273C104.627 7.04123 104.626 7.21067 104.626 7.44758C104.621 8.97852 104.623 10.5079 104.623 12.0389L104.618 12.0419Z" fill="#08030A" />
  <path d="M45.2659 8.57989C45.2659 6.09081 45.275 3.60023 45.2569 1.11116C45.2539 0.737793 45.364 0.646324 45.723 0.65832C46.5314 0.68381 47.3414 0.68381 48.1498 0.65832C48.5073 0.647824 48.6264 0.736286 48.6219 1.11265C48.6008 2.95247 48.6219 4.79379 48.6038 6.6336C48.6008 7.00247 48.6687 7.13443 49.0774 7.12843C50.8361 7.10294 52.5948 7.10294 54.3519 7.12843C54.7577 7.13443 54.8271 7.00997 54.824 6.63961C54.8044 4.78479 54.824 2.92848 54.8074 1.07217C54.8044 0.757285 54.8738 0.647822 55.2117 0.656819C56.082 0.682309 56.9538 0.673316 57.8256 0.659821C58.0805 0.655322 58.174 0.716794 58.174 0.991192C58.1664 6.0938 58.1664 11.1979 58.174 16.3005C58.174 16.5824 58.0654 16.6304 57.818 16.6274C56.9311 16.6169 56.0443 16.6094 55.1574 16.6304C54.8452 16.6379 54.8074 16.5134 54.809 16.251C54.818 14.2868 54.8029 12.3225 54.824 10.3597C54.8286 9.96838 54.7185 9.87841 54.3353 9.88291C52.5933 9.9054 50.8497 9.9084 49.1076 9.88141C48.6762 9.87391 48.5993 10.0119 48.6023 10.4047C48.6219 12.306 48.6008 14.2088 48.6204 16.1116C48.6249 16.5 48.5555 16.6574 48.1181 16.6364C47.3112 16.5974 46.4997 16.6049 45.6913 16.6334C45.3278 16.6469 45.2554 16.5254 45.2569 16.1881C45.2705 13.6525 45.2644 11.1155 45.2644 8.57989H45.2659Z" fill="#FD6125" />
  <path d="M126.278 9.94755C124.925 9.94755 123.57 9.95505 122.218 9.94156C121.91 9.93856 121.81 10.0315 121.834 10.3389C121.923 11.474 122.049 12.5821 123.006 13.3978C123.426 13.7561 123.877 13.9616 124.426 14.0035C126.399 14.155 128.376 14.047 130.35 14.068C130.687 14.071 130.812 14.131 130.795 14.4954C130.764 15.2046 130.776 15.9183 130.791 16.6291C130.797 16.8885 130.726 16.9755 130.454 16.9695C128.263 16.9245 126.064 17.0849 123.878 16.8675C121.35 16.6156 119.712 15.1821 118.845 12.8595C118.276 11.336 118.157 9.75112 118.224 8.12722C118.287 6.58279 118.524 5.09085 119.169 3.68737C120.091 1.68111 121.69 0.580516 123.893 0.360098C126.097 0.139679 128.307 0.315115 130.513 0.258136C130.705 0.253638 130.792 0.300123 130.789 0.513044C130.779 1.30175 130.773 2.09046 130.791 2.87916C130.798 3.18355 130.62 3.14457 130.432 3.14307C128.736 3.14307 127.04 3.11908 125.344 3.14757C122.85 3.19105 121.929 4.80746 121.834 6.79422C121.818 7.15109 122.064 7.05362 122.242 7.05362C123.89 7.05812 125.54 7.05662 127.189 7.05662C128.231 7.05662 129.275 7.07612 130.316 7.04613C130.718 7.03413 130.81 7.16608 130.792 7.53944C130.762 8.1872 130.764 8.83946 130.792 9.48722C130.807 9.84109 130.72 9.96254 130.338 9.95504C128.985 9.93105 127.631 9.94605 126.278 9.94605V9.94755Z" fill="#08030A" />
  <path d="M68.8173 9.94289C67.4644 9.94289 66.1099 9.94289 64.757 9.94289C64.5669 9.94289 64.3241 9.87541 64.3422 10.2113C64.4056 11.4138 64.5353 12.5804 65.5609 13.4351C65.9621 13.768 66.3935 13.9524 66.9033 13.9944C68.8762 14.1533 70.852 14.0439 72.8264 14.0604C73.1989 14.0634 73.2955 14.1623 73.2804 14.5222C73.2517 15.2164 73.2638 15.9137 73.2759 16.6094C73.2804 16.8493 73.2487 16.9708 72.9561 16.9648C70.7646 16.9183 68.5715 17.0383 66.3799 16.8718C64.1099 16.6994 62.0813 15.086 61.3241 12.8143C60.8369 11.3524 60.6695 9.85442 60.7208 8.307C60.7675 6.87353 60.9666 5.48503 61.4795 4.14903C62.3347 1.91935 63.941 0.608843 66.3528 0.356936C68.57 0.126022 70.7962 0.308949 73.0179 0.248972C73.2849 0.241474 73.2744 0.385422 73.2744 0.568354C73.2698 1.31058 73.2563 2.05281 73.2804 2.79353C73.2909 3.09942 73.1748 3.14291 72.9063 3.14141C71.2728 3.13091 69.6394 3.14141 68.0059 3.13391C65.5489 3.12192 64.4387 4.62886 64.3407 6.76856C64.3226 7.15392 64.5881 7.04596 64.7796 7.04746C67.4237 7.05196 70.0692 7.06545 72.7133 7.03696C73.208 7.03096 73.3121 7.17791 73.2834 7.62624C73.2442 8.22602 73.2502 8.8333 73.2834 9.43457C73.3045 9.82443 73.2201 9.96388 72.7872 9.95188C71.4659 9.9189 70.1431 9.93989 68.8203 9.93989L68.8173 9.94289Z" fill="#08030A" />
  <path d="M22.9556 9.89359C21.5408 9.89359 20.1245 9.90258 18.7097 9.88609C18.3884 9.88159 18.2889 9.95806 18.2949 10.2924C18.316 11.5025 18.6011 12.5671 19.6494 13.3333C20.2693 13.7861 20.9706 13.8491 21.681 13.8566C23.5317 13.8776 25.3824 13.8716 27.2331 13.8581C27.5438 13.8551 27.6675 13.9106 27.6524 14.2525C27.6222 14.9317 27.6313 15.6125 27.6494 16.2917C27.6569 16.5586 27.5679 16.6321 27.307 16.6276C25.0521 16.5946 22.7942 16.7251 20.5438 16.5481C17.7112 16.3262 15.5649 14.7323 14.8138 11.7094C14.1999 9.24283 14.2889 6.77624 15.2647 4.40412C16.1592 2.22992 17.8424 1.03187 20.1893 0.782962C22.577 0.531055 24.9752 0.718486 27.3673 0.661507C27.5513 0.657009 27.6509 0.689991 27.6479 0.90891C27.6373 1.65114 27.6313 2.39337 27.6494 3.13409C27.6569 3.44897 27.4593 3.386 27.2829 3.386C25.5408 3.386 23.7987 3.3695 22.0566 3.39349C21.1335 3.40699 20.2361 3.56893 19.4865 4.1792C18.6117 4.89144 18.3145 5.84358 18.2964 6.92468C18.2904 7.28755 18.5438 7.17059 18.7142 7.17059C21.4367 7.17659 24.1577 7.1751 26.8802 7.1751C27.389 7.1751 27.6434 7.43549 27.6434 7.9563C27.6434 8.46611 27.6237 8.97743 27.6509 9.48574C27.669 9.80512 27.583 9.90258 27.2467 9.89809C25.8153 9.87859 24.3854 9.88909 22.954 9.88909L22.9556 9.89359Z" fill="#FD6125" />
  <path d="M38.7966 16.6214C37.5855 16.5974 36.1526 16.7008 34.7393 16.4069C32.2913 15.8986 30.7152 14.4217 30.0424 12.0586C29.3109 9.4885 29.3109 6.90946 30.3411 4.40239C31.237 2.22069 32.9188 1.03313 35.2627 0.782724C37.6352 0.530818 40.0183 0.719754 42.3954 0.658277C42.6624 0.65078 42.6971 0.76623 42.6941 0.988148C42.6835 1.6524 42.673 2.31816 42.6986 2.98242C42.7107 3.3018 42.5945 3.36477 42.3004 3.36327C40.4965 3.35578 38.691 3.33478 36.8871 3.38126C35.1511 3.42625 34.0651 4.30192 33.5975 5.9738C33.1073 7.72815 33.0696 9.517 33.4557 11.2848C33.7031 12.4214 34.2808 13.3961 35.5447 13.7139C36.0108 13.8309 36.4934 13.8639 36.9761 13.8639C38.7333 13.8609 40.4919 13.8759 42.2491 13.8534C42.6307 13.8489 42.7122 13.9688 42.6971 14.3167C42.6699 14.9645 42.676 15.6152 42.6971 16.2645C42.7061 16.5509 42.6141 16.6319 42.3291 16.6289C41.225 16.6139 40.1194 16.6229 38.7996 16.6229L38.7966 16.6214Z" fill="#FD6125" />
  <path d="M6.93225 0.693748C9.09515 0.693748 11.2565 0.699747 13.4179 0.686252C13.7347 0.684753 13.8478 0.747735 13.8342 1.08511C13.8056 1.76436 13.8101 2.4451 13.8342 3.12435C13.8448 3.42274 13.7407 3.47972 13.4632 3.47672C12.0016 3.46322 10.5386 3.48422 9.07705 3.46173C8.7045 3.45573 8.61702 3.56369 8.61853 3.92356C8.63211 7.98855 8.62004 12.055 8.63663 16.12C8.63814 16.5204 8.55217 16.6553 8.12683 16.6358C7.3048 16.5984 6.47826 16.6029 5.65472 16.6358C5.25653 16.6508 5.13285 16.5609 5.13587 16.1365C5.15548 12.0865 5.14039 8.03653 5.15849 3.98653C5.16 3.56219 5.0514 3.45423 4.62908 3.46323C3.21429 3.49022 1.798 3.46323 0.383216 3.48122C0.0709978 3.48572 -0.00894189 3.39125 0.0016162 3.09286C0.0227324 2.4436 0.0287656 1.79434 0.0016162 1.14508C-0.0134668 0.792712 0.0725062 0.681752 0.451089 0.684751C2.61248 0.704244 4.77538 0.695248 6.93678 0.695248L6.93225 0.693748Z" fill="#FD6125" />
  <path d="M106.976 5.87967C106.976 4.13281 106.983 2.38746 106.97 0.642108C106.967 0.346717 107.038 0.246252 107.35 0.255249C108.142 0.27774 108.937 0.282239 109.728 0.255249C110.078 0.243253 110.164 0.339218 110.163 0.687089C110.149 4.05634 110.155 7.4241 110.157 10.7933C110.157 11.3511 110.199 11.9074 110.392 12.4322C110.822 13.6063 111.704 14.1656 113.163 14.1851C114.469 14.2016 115.775 14.1971 117.081 14.1851C117.374 14.1821 117.489 14.2436 117.477 14.5615C117.451 15.2407 117.459 15.9214 117.474 16.6007C117.48 16.8676 117.428 16.9846 117.121 16.9771C115.241 16.9321 113.36 17.0295 111.481 16.8901C109.561 16.7476 107.769 15.2002 107.271 13.179C107.083 12.4142 106.986 11.633 106.983 10.8443C106.977 9.19044 106.982 7.53806 106.982 5.88417H106.979L106.976 5.87967Z" fill="#08030A" />
  <path d="M90.2591 16.548C89.6618 15.0651 89.0766 13.5791 88.4944 12.0902C86.9952 8.25458 85.4959 4.41899 84.0072 0.580414C83.9062 0.32251 83.7734 0.243036 83.5035 0.249034C82.6332 0.265528 81.7614 0.27603 80.8911 0.246041C80.5442 0.234045 80.4069 0.367493 80.2938 0.664383C78.4627 5.44312 76.6226 10.2173 74.787 14.9931C74.5411 15.6333 74.3013 16.2781 74.0449 16.9589C75.0706 16.9589 76.0344 16.9424 76.9967 16.9678C77.3315 16.9768 77.4763 16.8734 77.5728 16.5555C77.9047 15.4684 78.2772 14.3948 78.6151 13.3092C78.698 13.0453 78.8202 12.9583 79.1022 12.9598C81.0932 12.9733 83.0842 12.9733 85.0736 12.9598C85.3526 12.9583 85.4808 13.0393 85.5683 13.3032C85.9394 14.4098 86.3376 15.5074 86.7146 16.611C86.7976 16.8554 86.9047 16.9738 87.1973 16.9663C88.1294 16.9424 89.063 16.9409 89.9967 16.9663C90.3979 16.9768 90.3768 16.8434 90.2576 16.548H90.2591ZM79.8006 9.99244C80.5442 7.88272 81.2802 5.79849 82.063 3.58231C82.8654 5.78949 83.6241 7.87822 84.3918 9.99244H79.8021H79.8006Z" fill="#08030A" />
</svg>
const LeftPanelHomePage = ({ userCookie, selectedTab, setSelectedTab, subContent, themeGlobal, myHubLocation }) => {
  const navigate = useNavigate()

  const handelHome = async () => {
    navigate('/home')
    setSelectedTab("Home")
  }
  const handleBack = () => {
    console.log("window.history=>", window, subContent)
    if (window.history.length > 1) {
      navigate(-1); // Navigate back
    }
    // const tabName = navlinks[window.parent.location.pathname]
    //   console.log("tabName", tabName)
    //   setSelectedTab(tabName)
  };
  // const handelsetCustomerDetailsPopup = () => {
  //   setCustomerDetailsPopup(true)
  // }
  // className = 'leftPanel-ImageIcon'

  const tabsAvailableUser = [
    {
      label: "Home", image: <svg className='leftPanel-ImageIcon' width="20" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 16.9945V13.9904M8.02 1.82371L2.63 6.02948C1.73 6.73044 1 8.22248 1 9.35404V16.7742C1 19.0974 2.89 21 5.21 21H16.79C19.11 21 21 19.0974 21 16.7842V9.49423C21 8.28257 20.19 6.73044 19.2 6.03949L13.02 1.70355C11.62 0.722204 9.37 0.772272 8.02 1.82371Z" stroke={selectedTab === "Home" ? "#FD6125" : "#BCBFCB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    },
    {
      label: "My Orders", image: <svg className='leftPanel-ImageIcon' xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
        <path d="M5.37185 6.66395V5.69354C5.37185 3.44259 7.2334 1.23166 9.54747 1.02157C10.1904 0.960124 10.8395 1.0303 11.4529 1.2276C12.0663 1.42489 12.6305 1.74492 13.1091 2.1671C13.5877 2.58928 13.9701 3.10425 14.2317 3.67886C14.4933 4.25348 14.6284 4.87501 14.6281 5.50346V6.88405M6.91457 21H13.0854C17.2199 21 17.9604 19.3893 18.1764 17.4285L18.9477 11.426C19.2254 8.98493 18.5055 6.99409 14.1139 6.99409H5.88609C1.4945 6.99409 0.774561 8.98493 1.05225 11.426L1.82361 17.4285C2.03959 19.3893 2.78009 21 6.91457 21Z" stroke={selectedTab === "My Orders" ? "#FD6125" : "#BCBFCB"} strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5919 10.9961H13.6022M6.39258 10.9961H6.40081" stroke={selectedTab === "My Orders" ? "#FD6125" : "#BCBFCB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    },
    {
      label: "My Profile", image: <svg className='leftPanel-ImageIcon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 9C16 10.0609 15.5786 11.0783 14.8284 11.8284C14.0783 12.5786 13.0609 13 12 13C10.9391 13 9.92172 12.5786 9.17157 11.8284C8.42143 11.0783 8 10.0609 8 9C8 7.93913 8.42143 6.92172 9.17157 6.17157C9.92172 5.42143 10.9391 5 12 5C13.0609 5 14.0783 5.42143 14.8284 6.17157C15.5786 6.92172 16 7.93913 16 9ZM14 9C14 9.53043 13.7893 10.0391 13.4142 10.4142C13.0391 10.7893 12.5304 11 12 11C11.4696 11 10.9609 10.7893 10.5858 10.4142C10.2107 10.0391 10 9.53043 10 9C10 8.46957 10.2107 7.96086 10.5858 7.58579C10.9609 7.21071 11.4696 7 12 7C12.5304 7 13.0391 7.21071 13.4142 7.58579C13.7893 7.96086 14 8.46957 14 9Z" fill={selectedTab === "My Profile" ? "#FD6125" : "#BCBFCB"} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM3 12C3 14.09 3.713 16.014 4.908 17.542C5.74744 16.4401 6.83015 15.5471 8.07164 14.9327C9.31312 14.3183 10.6798 13.9991 12.065 14C13.4324 13.9984 14.7821 14.3091 16.0111 14.9084C17.2402 15.5077 18.3162 16.3797 19.157 17.458C20.0234 16.3216 20.6068 14.9952 20.8589 13.5886C21.111 12.182 21.0244 10.7355 20.6065 9.36898C20.1886 8.00243 19.4512 6.75505 18.4555 5.73004C17.4598 4.70503 16.2343 3.93186 14.8804 3.47451C13.5265 3.01716 12.0832 2.88877 10.6699 3.09997C9.25652 3.31117 7.91379 3.85589 6.75277 4.68905C5.59175 5.52222 4.64581 6.61987 3.99323 7.8912C3.34065 9.16252 3.00018 10.571 3 12ZM12 21C9.93391 21.0033 7.93014 20.2926 6.328 18.988C6.97281 18.0646 7.83119 17.3107 8.83008 16.7905C9.82896 16.2702 10.9388 15.999 12.065 16C13.1772 15.999 14.2735 16.2635 15.263 16.7713C16.2524 17.2792 17.1064 18.0158 17.754 18.92C16.1395 20.267 14.1026 21.0033 12 21Z" fill={selectedTab === "My Profile" ? "#FD6125" : "#BCBFCB"} />
      </svg>
    }]
  const tabsAvailableInventoryManager = [
    {
      label: "Dashboard", image: <svg className='leftPanel-ImageIcon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 9V3H21V9H13ZM3 13V3H11V13H3ZM13 21V11H21V21H13ZM3 21V15H11V21H3Z" fill={selectedTab === "Dashboard" ? "#FD6125" : "#BCBFCB"} />
      </svg>
      // stroke={selectedTab === "Dashboard" ? "#FD6125" : "#BCBFCB"}
    },
    {
      label: "Create Order", image: <svg className='leftPanel-ImageIcon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 9V6H8V4H11V1H13V4H16V6H13V9H11ZM7 22C6.45 22 5.97933 21.8043 5.588 21.413C5.19667 21.0217 5.00067 20.5507 5 20C4.99933 19.4493 5.19533 18.9787 5.588 18.588C5.98067 18.1973 6.45133 18.0013 7 18C7.54867 17.9987 8.01967 18.1947 8.413 18.588C8.80633 18.9813 9.002 19.452 9 20C8.998 20.548 8.80233 21.019 8.413 21.413C8.02367 21.807 7.55267 22.0027 7 22ZM17 22C16.45 22 15.9793 21.8043 15.588 21.413C15.1967 21.0217 15.0007 20.5507 15 20C14.9993 19.4493 15.1953 18.9787 15.588 18.588C15.9807 18.1973 16.4513 18.0013 17 18C17.5487 17.9987 18.0197 18.1947 18.413 18.588C18.8063 18.9813 19.002 19.452 19 20C18.998 20.548 18.8023 21.019 18.413 21.413C18.0237 21.807 17.5527 22.0027 17 22ZM1 4V2H4.275L8.525 11H15.525L19.425 4H21.7L17.3 11.95C17.1167 12.2833 16.871 12.5417 16.563 12.725C16.255 12.9083 15.9173 13 15.55 13H8.1L7 15H19V17H7C6.25 17 5.679 16.675 5.287 16.025C4.895 15.375 4.88267 14.7167 5.25 14.05L6.6 11.6L3 4H1Z" fill={selectedTab === "Create Order" ? "#FD6125" : "#BCBFCB"} />
      </svg>
    },
    {
      label: "Manage Orders", image: <svg className='leftPanel-ImageIcon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 18C17.5304 18 18.0391 18.2107 18.4142 18.5858C18.7893 18.9609 19 19.4696 19 20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22C16.4696 22 15.9609 21.7893 15.5858 21.4142C15.2107 21.0391 15 20.5304 15 20C15 18.89 15.89 18 17 18ZM1 2H4.27L5.21 4H20C20.2652 4 20.5196 4.10536 20.7071 4.29289C20.8946 4.48043 21 4.73478 21 5C21 5.17 20.95 5.34 20.88 5.5L17.3 11.97C16.96 12.58 16.3 13 15.55 13H8.1L7.2 14.63L7.17 14.75C7.17 14.8163 7.19634 14.8799 7.24322 14.9268C7.29011 14.9737 7.3537 15 7.42 15H19V17H7C6.46957 17 5.96086 16.7893 5.58579 16.4142C5.21071 16.0391 5 15.5304 5 15C5 14.65 5.09 14.32 5.24 14.04L6.6 11.59L3 4H1V2ZM7 18C7.53043 18 8.03914 18.2107 8.41421 18.5858C8.78929 18.9609 9 19.4696 9 20C9 20.5304 8.78929 21.0391 8.41421 21.4142C8.03914 21.7893 7.53043 22 7 22C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20C5 18.89 5.89 18 7 18ZM16 11L18.78 6H6.14L8.5 11H16Z" fill={selectedTab === "Manage Orders" ? "#FD6125" : "#BCBFCB"} />
      </svg>
    },
    {
      label: "Manage Inventory", image: <svg className='leftPanel-ImageIcon' width="18" height="20" viewBox="0 0 77 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M55.75 32.3742L21.25 12.4705" stroke={selectedTab === "Manage Inventory" ? "#FD6125" : "#BCBFCB"} strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M73 57.6824V27.0022C72.9986 25.6572 72.6437 24.3362 71.9709 23.1717C71.2981 22.0072 70.331 21.0402 69.1667 20.3677L42.3333 5.02759C41.1679 4.3544 39.8458 4 38.5 4C37.1542 4 35.8321 4.3544 34.6667 5.02759L7.83333 20.3677C6.66901 21.0402 5.70192 22.0072 5.0291 23.1717C4.35628 24.3362 4.00138 25.6572 4 27.0022V57.6824C4.00138 59.0274 4.35628 60.3485 5.0291 61.5129C5.70192 62.6774 6.66901 63.6444 7.83333 64.317L34.6667 79.657C35.8321 80.3302 37.1542 80.6846 38.5 80.6846C39.8458 80.6846 41.1679 80.3302 42.3333 79.657L69.1667 64.317C70.331 63.6444 71.2981 62.6774 71.9709 61.5129C72.6437 60.3485 72.9986 59.0274 73 57.6824Z" stroke={selectedTab === "Manage Inventory" ? "#FD6125" : "#BCBFCB"} strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.03516 23.0156L38.5002 42.3825L71.9651 23.0156" stroke={selectedTab === "Manage Inventory" ? "#FD6125" : "#BCBFCB"} strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M38.5 81.0007V42.3438" stroke={selectedTab === "Manage Inventory" ? "#FD6125" : "#BCBFCB"} strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    }, {
      label: "My Profile", image: <svg className='leftPanel-ImageIcon' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 9C16 10.0609 15.5786 11.0783 14.8284 11.8284C14.0783 12.5786 13.0609 13 12 13C10.9391 13 9.92172 12.5786 9.17157 11.8284C8.42143 11.0783 8 10.0609 8 9C8 7.93913 8.42143 6.92172 9.17157 6.17157C9.92172 5.42143 10.9391 5 12 5C13.0609 5 14.0783 5.42143 14.8284 6.17157C15.5786 6.92172 16 7.93913 16 9ZM14 9C14 9.53043 13.7893 10.0391 13.4142 10.4142C13.0391 10.7893 12.5304 11 12 11C11.4696 11 10.9609 10.7893 10.5858 10.4142C10.2107 10.0391 10 9.53043 10 9C10 8.46957 10.2107 7.96086 10.5858 7.58579C10.9609 7.21071 11.4696 7 12 7C12.5304 7 13.0391 7.21071 13.4142 7.58579C13.7893 7.96086 14 8.46957 14 9Z" fill={selectedTab === "My Profile" ? "#FD6125" : "#BCBFCB"} />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM3 12C3 14.09 3.713 16.014 4.908 17.542C5.74744 16.4401 6.83015 15.5471 8.07164 14.9327C9.31312 14.3183 10.6798 13.9991 12.065 14C13.4324 13.9984 14.7821 14.3091 16.0111 14.9084C17.2402 15.5077 18.3162 16.3797 19.157 17.458C20.0234 16.3216 20.6068 14.9952 20.8589 13.5886C21.111 12.182 21.0244 10.7355 20.6065 9.36898C20.1886 8.00243 19.4512 6.75505 18.4555 5.73004C17.4598 4.70503 16.2343 3.93186 14.8804 3.47451C13.5265 3.01716 12.0832 2.88877 10.6699 3.09997C9.25652 3.31117 7.91379 3.85589 6.75277 4.68905C5.59175 5.52222 4.64581 6.61987 3.99323 7.8912C3.34065 9.16252 3.00018 10.571 3 12ZM12 21C9.93391 21.0033 7.93014 20.2926 6.328 18.988C6.97281 18.0646 7.83119 17.3107 8.83008 16.7905C9.82896 16.2702 10.9388 15.999 12.065 16C13.1772 15.999 14.2735 16.2635 15.263 16.7713C16.2524 17.2792 17.1064 18.0158 17.754 18.92C16.1395 20.267 14.1026 21.0033 12 21Z" fill={selectedTab === "My Profile" ? "#FD6125" : "#BCBFCB"} />
      </svg>
    }]

  return (
    <div className='leftpanel-container'>
      <div className='leftpanel-container__logodiv'>
        <img className='leftpanel-container__logodiv__logo' src={backEndIP + "/" + themeGlobal.nameLogo} alt="Logo" onClick={handelHome} />
        {/* <div className='leftpanel-container__logodiv__backButton' >
          <ArowBackIcon style={{ fontSize: '20px', marginRight: '8px' }} />
        </div> */}
      </div>
      {/* <div className="leftpanel-container__logodiv-border"></div> */}
      {/* <div className="leftpanel-container__backButton" >
        <div className="leftpanel-container__backButton__container" onClick={handleBack}>
        <svg className="leftpanel-container__backButton__container__svgimag" width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 13L18 7L11 1"  strokeWidth="1.5" strokeLinecap="round" />
          <path d="M1 6.25C0.585787 6.25 0.25 6.58578 0.25 7C0.25 7.41421 0.585787 7.75 1 7.75L1 6.25ZM18 6.25L1 6.25L1 7.75L18 7.75L18 6.25Z" />
        </svg>
        <div className="leftpanel-container__backButton__container__textBack">
          Back
        </div>
        </div>
      </div> */}
      {userCookie["permission"] === "Not Granted" && <div className='leftpanel-container__tabsContainer'>
        {tabsAvailableUser.map((tabs, i) => {
          // console.log("tab", tabs)
          return <LeftPanelTabLabels key={`${"tabsAvailableUser"}${tabs.label}${i}`} selected={selectedTab} setSelected={setSelectedTab} subContent={subContent} label={tabs.label} icon={tabs.image} />
        })}
      </div>}
      {userCookie["permission"] === "Granted" && <div className='leftpanel-container__tabsContainer'>
        {tabsAvailableInventoryManager
          .filter((tabs) => !(tabs.label === "Manage Inventory" && !myHubLocation?.flags?.customerOrder))
          .map((tabs, i) => (
            <LeftPanelTabLabels
              key={`tabsAvailableInventoryManager${tabs.label}${i}`}
              selected={selectedTab}
              setSelected={setSelectedTab}
              subContent={subContent}
              label={tabs.label}
              icon={tabs.image}
            />
          ))}
      </div>}
      {/* 
        {userCookie.permission === "Not Granted" && <LeftPanelTabLabels selected={selectedTab} setSelected={setSelectedTab} subContent={subContent} label='Home' icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 16.9945V13.9904M8.02 1.82371L2.63 6.02948C1.73 6.73044 1 8.22248 1 9.35404V16.7742C1 19.0974 2.89 21 5.21 21H16.79C19.11 21 21 19.0974 21 16.7842V9.49423C21 8.28257 20.19 6.73044 19.2 6.03949L13.02 1.70355C11.62 0.722204 9.37 0.772272 8.02 1.82371Z" stroke={selectedTab === "Home" ? "#FD6125" : "#BCBFCB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        } />}
        {userCookie.permission === "Granted" && <LeftPanelTabLabels selected={selectedTab} setSelected={setSelectedTab} subContent={subContent} label='Dashboard' icon={<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 16.9945V13.9904M8.02 1.82371L2.63 6.02948C1.73 6.73044 1 8.22248 1 9.35404V16.7742C1 19.0974 2.89 21 5.21 21H16.79C19.11 21 21 19.0974 21 16.7842V9.49423C21 8.28257 20.19 6.73044 19.2 6.03949L13.02 1.70355C11.62 0.722204 9.37 0.772272 8.02 1.82371Z" stroke={selectedTab === "Dashboard" ? "#FD6125" : "#BCBFCB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        } />}

        {userCookie.permission === "Not Granted" && <LeftPanelTabLabels selected={selectedTab} setSelected={setSelectedTab} subContent={subContent} label='My Orders' icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
          <path d="M5.37185 6.66395V5.69354C5.37185 3.44259 7.2334 1.23166 9.54747 1.02157C10.1904 0.960124 10.8395 1.0303 11.4529 1.2276C12.0663 1.42489 12.6305 1.74492 13.1091 2.1671C13.5877 2.58928 13.9701 3.10425 14.2317 3.67886C14.4933 4.25348 14.6284 4.87501 14.6281 5.50346V6.88405M6.91457 21H13.0854C17.2199 21 17.9604 19.3893 18.1764 17.4285L18.9477 11.426C19.2254 8.98493 18.5055 6.99409 14.1139 6.99409H5.88609C1.4945 6.99409 0.774561 8.98493 1.05225 11.426L1.82361 17.4285C2.03959 19.3893 2.78009 21 6.91457 21Z" stroke={selectedTab === "My Orders" ? "#FD6125" : "#BCBFCB"} strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M13.5919 10.9961H13.6022M6.39258 10.9961H6.40081" stroke={selectedTab === "My Orders" ? "#FD6125" : "#BCBFCB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>} />}
        {userCookie.permission === "Granted" && <LeftPanelTabLabels selected={selectedTab} setSelected={setSelectedTab} subContent={subContent} label='Order Management' icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 24 24" fill='none'>
          <path d="M18.5,11c3.033,0,5.5-2.468,5.5-5.5S21.533,0,18.5,0s-5.5,2.468-5.5,5.5,2.467,5.5,5.5,5.5Zm0-10c2.481,0,4.5,2.019,4.5,4.5s-2.019,4.5-4.5,4.5-4.5-2.019-4.5-4.5,2.019-4.5,4.5-4.5Zm-.354,4.854c-.094-.094-.146-.221-.146-.354V3c0-.276,.224-.5,.5-.5s.5,.224,.5,.5v2.293l1.354,1.354c.195,.195,.195,.512,0,.707-.098,.098-.226,.146-.354,.146s-.256-.049-.354-.146l-1.5-1.5Zm-1.146,14.146c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm0,3c-.551,0-1-.448-1-1s.449-1,1-1,1,.448,1,1-.449,1-1,1Zm-10-3c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm0,3c-.551,0-1-.448-1-1s.449-1,1-1,1,.448,1,1-.449,1-1,1Zm15.565-10.264c-.8,1.396-2.297,2.264-3.906,2.264H6.02c.247,1.711,1.732,3,3.462,3h10.017c.276,0,.5,.224,.5,.5s-.224,.5-.5,.5H9.483c-2.234,0-4.15-1.669-4.457-3.882L3.245,2.293c-.103-.737-.742-1.293-1.486-1.293H.5c-.276,0-.5-.224-.5-.5S.224,0,.5,0H1.759c1.24,0,2.305,.927,2.476,2.155l.256,1.845h6.009c.276,0,.5,.224,.5,.5s-.224,.5-.5,.5H4.63l1.25,9h12.78c1.251,0,2.416-.675,3.039-1.762,.136-.24,.442-.321,.682-.185s.323,.442,.185,.683Z" stroke={selectedTab === "Order Management" ? "#FD6125" : "#BCBFCB"} strokeLinecap="round" strokeLinejoin="round" />
        </svg>} />}

        {userCookie.permission === "Granted" && <LeftPanelTabLabels selected={selectedTab} setSelected={setSelectedTab} subContent={subContent} setCustomerDetailsPopup={handelsetCustomerDetailsPopup} label='Create Order' icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 24 24" fill="none">
          <path d="M21.59,15H6.65l.13,1.12c.06,.5,.49,.88,.99,.88h12.22v2H7.78c-1.52,0-2.8-1.14-2.98-2.65L3.21,2.88c-.06-.5-.49-.88-.99-.88H0V0H2.22c1.52,0,2.8,1.14,2.98,2.65l.04,.35h4.76v2H5.48l.94,8h13.54l1.6-8h-5.55V3h7.99l-2.4,12Zm-14.59,5c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Zm10,0c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2ZM8.89,7.72l2.69,2.69c.39,.39,.9,.58,1.41,.58s1.02-.19,1.41-.58l2.68-2.68-1.41-1.41-1.68,1.68V0h-2V8l-1.69-1.69-1.41,1.41Z" stroke={selectedTab === "Create Order" ? "#FD6125" : "#BCBFCB"} strokeLinecap="round" strokeLinejoin="round" />
        </svg>} />} */}


      {/* {userCookie.permission === "Granted" &&<LeftPanelTabLabels classes='w-full mt-6 h-7 pl-12' subContent={subContent} selected={selectedTab} setSelected={setSelectedTab} label='Approvals' icon={<svg width="22" height="22" viewBox="0 0 83 83" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M81.8118 10.1551C83.383 11.708 83.3978 14.2406 81.8449 15.8118L45.2749 52.8118C44.5235 53.5721 43.499 54 42.43 54C41.361 54 40.3365 53.5721 39.5851 52.8118L23.1551 36.1887C21.6021 34.6175 21.617 32.0848 23.1882 30.5319C24.7594 28.979 27.292 28.9938 28.8449 30.565L42.43 44.3098L76.1551 10.1882C77.708 8.61695 80.2406 8.60215 81.8118 10.1551Z" fill={selectedTab === "Approvals" ? "#FD6125" : "#BCBFCB"} />
          <path fill-rule="evenodd" clip-rule="evenodd" d="M75 41.5C75 60.0015 60.0015 75 41.5 75C22.9985 75 8 60.0015 8 41.5C8 22.9985 22.9985 8 41.5 8C49.8298 8 57.4495 11.0402 63.3101 16.0715L68.945 10.3702C61.6299 3.91605 52.0223 0 41.5 0C18.5802 0 0 18.5802 0 41.5C0 64.4198 18.5802 83 41.5 83C64.4198 83 83 64.4198 83 41.5C83 35.6918 81.8068 30.1622 79.6521 25.1432L73.4548 31.4133C74.4587 34.5966 75 37.9851 75 41.5Z" fill={selectedTab === "Approvals" ? "#FD6125" : "#BCBFCB"} />
        </svg>} />} */}

      {/* {userCookie.permission === "Granted" && <LeftPanelTabLabels selected={selectedTab} setSelected={setSelectedTab} subContent={subContent} label='Inventory Management' icon={<svg width="22" height="22" viewBox="0 0 77 85" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M55.75 32.3742L21.25 12.4705" stroke={selectedTab === "Inventory Management" ? "#FD6125" : "#BCBFCB"} strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M73 57.6824V27.0022C72.9986 25.6572 72.6437 24.3362 71.9709 23.1717C71.2981 22.0072 70.331 21.0402 69.1667 20.3677L42.3333 5.02759C41.1679 4.3544 39.8458 4 38.5 4C37.1542 4 35.8321 4.3544 34.6667 5.02759L7.83333 20.3677C6.66901 21.0402 5.70192 22.0072 5.0291 23.1717C4.35628 24.3362 4.00138 25.6572 4 27.0022V57.6824C4.00138 59.0274 4.35628 60.3485 5.0291 61.5129C5.70192 62.6774 6.66901 63.6444 7.83333 64.317L34.6667 79.657C35.8321 80.3302 37.1542 80.6846 38.5 80.6846C39.8458 80.6846 41.1679 80.3302 42.3333 79.657L69.1667 64.317C70.331 63.6444 71.2981 62.6774 71.9709 61.5129C72.6437 60.3485 72.9986 59.0274 73 57.6824Z" stroke={selectedTab === "Inventory Management" ? "#FD6125" : "#BCBFCB"} strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M5.03516 23.0156L38.5002 42.3825L71.9651 23.0156" stroke={selectedTab === "Inventory Management" ? "#FD6125" : "#BCBFCB"} strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M38.5 81.0007V42.3438" stroke={selectedTab === "Inventory Management" ? "#FD6125" : "#BCBFCB"} strokeWidth="8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>} />} */}

    </div>
  )
}

export default LeftPanelHomePage
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import "./inventory.scss";
import { backEndIP } from "../configuration";

const InventoryForm = ({
  selectedCategory,
  userCookie,
  SetLoadingVisibility,
  handleNotifyAlert,
}) => {
  const fileInputElement = useRef();
  const inputBase64data = useRef();
  const fileNameTextBox = useRef();
  const [categories, setCategories] = useState([]);
  const [item_image, setItem_image] = useState("");
  // const [category, setCategory] = useState("Medicine");
  // const [name, setName] = useState("");
  // const [weight, setWeight] = useState("");
  // const [quanity, setQuantity] = useState("");
  const [inventList, setInvetList] = useState({
    name: "",
    filedata: "default_medicine",
    description: "Generic Medicine",
    available_quantity: 0,
    weight: 0,
    v_weight: 0,
    price: 0,
    location: "",
    category: "",
  });
  const styleNumberInput = {
    width: "100%",
    height: "40%",
    ".MuiInputBase-input": {
      padding:
        "calc(8px * (var(--heightmultiplier) + var(--heightmultiplier)) / 2) ",
      fontSize: "calc(20px * var(--heightmultiplier))",
      backgroundColor: "var(--secondaryColor)",
      fontFamily: "General Sans",
    },
    ".MuiInputLabel-root": {
      padding: "0px",
      fontSize: "calc(20px * var(--heightmultiplier))",
      fontFamily: "General Sans",
      color: "#FD6125",
    },
    ".Mui-focused": {
      fontSize: "calc(20px * var(--heightmultiplier))",
      fontFamily: "General Sans",
      color: "var(--primaryTextColor)",
      borderColor: "var(--secondaryColor)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--primaryTextColor)",
        fontSize: "calc(20px * var(--heightmultiplier))",
      },
      "&:hover fieldset": {
        borderColor: "#FD6125",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FD6125",
        fontSize: "calc(24px * var(--heightmultiplier))",
      },
    },
  };
  const styleNameInput = {
    width: "100%",
    ".MuiInputBase-input": {
      height: "calc(28px * var(--heightmultiplier))",
      height: "fit-content",
      padding:
        "calc(8px * (var(--heightmultiplier) + var(--heightmultiplier)) / 2) ",
      fontSize: "calc(20px * var(--heightmultiplier))",
      backgroundColor: "var(--secondaryColor)",
      fontFamily: "General Sans",
    },
    ".MuiInputLabel-root": {
      padding: "0px",
      //margin: "calc(-10px * (var(--heightmultiplier)))",
      fontSize: "calc(16px * var(--heightmultiplier))",
      fontFamily: "General Sans",
      color: "#FD6125",
    },
    ".Mui-focused": {
      padding: "0px",
      fontSize: "calc(20px * var(--heightmultiplier))",
      //margin: "calc(-1px * (var(--heightmultiplier)))",
      fontFamily: "General Sans",
      color: "var(--primaryTextColor)",
      borderColor: "var(--secondaryColor)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--primaryTextColor)",
        fontSize: "calc(20px * var(--heightmultiplier))",
      },
      "&:hover fieldset": {
        borderColor: "#FD6125",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FD6125",
      },
    },
  };
  useEffect(() => {
    axios({
      url: backEndIP + "/category/get_category",
      method: "GET",
      headers: {
        authorization: userCookie.token,
      },
    }).then((data) => {
      //console.log(data.data.data[0]);
      setCategories(data?.data?.data);
     setInvetList({...inventList, ["category"]:data?.data?.data[0]?.name})
      // setCategory(data.data.data[0].name)
    });
  }, []);

  // useEffect(() => {
  //   if (inventList.category !== "Medicines") {
  //     inventList.description = "";
  //     inventList.filedata = "";
  //   } else {
  //     setItem_image("");
  //     if(inventList. === "Medicines")
  //     inventList.description = "Generic Medicine";
  //     inventList.filedata = "default_medicine";
  //   }
  // }, [inventList.category]);

  const handleInventoryProduct = (e) => {
    // console.log(inventList);
    e.preventDefault();
    SetLoadingVisibility(true);
    if (
      inventList.name === "" ||
      inventList.available_quantity === 0 ||
      inventList.weight === 0 ||
      inventList.available_quantity === "" ||
      inventList.weight === ""
    ) {
      SetLoadingVisibility(false);
      handleNotifyAlert(
        "notify",
        `Please fill all the Data`,
        "failed",
        () => {}
      );
    } else {
      axios({
        url: backEndIP + "/inventory/add-item-inventory",
        method: "POST",
        headers: {
          authorization: userCookie.token,
        },
        data: {
          ...inventList,
        },
        // data: { id: product._id , quantity:1 }
      })
        .then((userData) => {
          SetLoadingVisibility(false);
          handleNotifyAlert(
            "notify",
            `Item added to Inventory successfully`,
            "success",
            () => {}
          );
          //console.log(userData);
          setInvetList({
            name: "",
            filedata: "default_medicine",
            description: "",
            available_quantity: 0,
            weight: 0,
            v_weight: 0,
            price: 0,
            location: "",
            category: "Medicines",
          });
          setItem_image("");
          fileNameTextBox.current.value = "No File Selected";
        })
        .catch((err) => {
          // console.log(err);
          SetLoadingVisibility(false);
          handleNotifyAlert(
            "notify",
            `${err.response.data.message}`,
            "failed",
            () => {}
          );
        });
    }
  };
  // console.log("categories0", categories)
  const handleFileOnChange = () => {
    const reader = new FileReader();
    // setItem_image(e.target.value);
    if (fileNameTextBox.current.value === "") {
    }
    if (fileInputElement.current.files.length) {
      fileNameTextBox.current.value = fileInputElement.current.files[0].name;
      // console.log(fileInputElement.current.files);
      reader.onload = () => {
        // console.log(reader.result);
        inputBase64data.current.value = reader.result;
        setItem_image(reader.result);
        setInvetList({ ...inventList, filedata: reader.result });
      };
      reader.readAsDataURL(fileInputElement.current.files[0]);
    } else {
      console.log("No File Selected");
    }
  };
  const handleBrowseClick = () => {
    fileInputElement.current.click();
  };

  const handleUpdateItem = (e, id) => {
    if (id === "weight" || id === "price" || id === "available_quantity") {
      setInvetList({ ...inventList, [id]: parseInt(e.target.value) });
    } else {
      //setCategory({category, [id]: e.target.value})
      setInvetList({ ...inventList, [id]: e.target.value });
    }
  };
  //console.log(category);
  return (
    <div className="InvListSubContent-container">
      <div className="InvListSubContent-container__subContainer">
        <div className="InvListSubContent-container__subContainer__inside">
          <div className="InvListSubContent-container__subContainer__left">
            <div className="InvListSubContent-container__subContainer__inputFiled">
              <div className="InvListSubContent-container__subContainer__inputFiled__label">
                Category<div style={{ color: "red" }}> * </div>
              </div>
              <div className="InvListSubContent-container__subContainer__inputFiled__value">
                <select
                  type="text"
                  value={inventList.category}
                  min={1}
                  // placeholder="Enter Product Name"
                  className="InvListSubContent-container__subContainer__inputFiled__value__input"
                  onChange={(e) => {
                    handleUpdateItem(e, "category");
                  }}
                >
                  {categories.map((option) => (
                    <option key={option._id} value={option.name}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="InvListSubContent-container__subContainer__leftBox">
              <div className="InvListSubContent-container__subContainer__inputFiled">
                <div className="InvListSubContent-container__subContainer__inputFiled__label">
                  Name<div style={{ color: "red" }}> * </div>
                </div>
                <div className="InvListSubContent-container__subContainer__inputFiled__value">
                  <input
                    type="text"
                    value={inventList.name}
                    min={1}
                    placeholder="Enter Product Name"
                    className="InvListSubContent-container__subContainer__inputFiled__value__input"
                    onChange={(e) => {
                      handleUpdateItem(e, "name");
                    }}
                  />
                </div>
              </div>
              <div className="InvListSubContent-container__subContainer__inputFiled">
                <div className="InvListSubContent-container__subContainer__inputFiled__label">
                  Quantity <div style={{ color: "red" }}> * </div>
                </div>
                <div className="InvListSubContent-container__subContainer__inputFiled__value">
                  <input
                    type="number"
                    value={inventList.available_quantity}
                    min={1}
                    //placeholder="Enter Old Password"
                    className="InvListSubContent-container__subContainer__inputFiled__value__input"
                    onChange={(e) => {
                      handleUpdateItem(e, "available_quantity");
                    }}
                  />
                </div>
              </div>
              <div className="InvListSubContent-container__subContainer__inputFiled">
                <div className="InvListSubContent-container__subContainer__inputFiled__label">
                  Weight <div style={{ color: "red" }}> * </div>
                </div>
                <div className="InvListSubContent-container__subContainer__inputFiled__value">
                  <input
                    type="number"
                    value={inventList.weight}
                    min={1}
                    //placeholder="Enter Old Password"
                    className="InvListSubContent-container__subContainer__inputFiled__value__input"
                    onChange={(e) => {
                      handleUpdateItem(e, "weight");
                    }}
                  />
                </div>
              </div>
              <div className="InvListSubContent-container__subContainer__inputFiled2">
                <div className="InvListSubContent-container__subContainer__inputFiled2__imageInput">
                  <label className="InvListSubContent-container__subContainer__inputFiled2__imageInput__label">
                    Image<div style={{ color: "red" }}>*</div>
                  </label>
                  <input
                    ref={fileNameTextBox}
                    type="text"
                    className="InvListSubContent-container__subContainer__inputFiled2__imageInput__file_input_textbox2"
                    readOnly="readOnly"
                    placeholder="No File Selected"
                  ></input>
                  <input
                    ref={fileInputElement}
                    type="file"
                    name="uploaded_file"
                    onChange={handleFileOnChange}
                    className="InvListSubContent-container__subContainer__inputFiled2__imageInput__file_input_hidden"
                  />
                  <input
                    ref={inputBase64data}
                    type="text"
                    name="filedata"
                    readOnly="readOnly"
                    className="InvListSubContent-container__subContainer__inputFiled2__imageInput__file_input_hidden"
                  ></input>
                </div>
                <div className="InvListSubContent-container__subContainer__inputFiled2__imageButton">
                  <input
                    type="button"
                    onClick={handleBrowseClick}
                    value="Browse"
                    className="InvListSubContent-container__subContainer__inputFiled2__imageButton__file_input_button"
                    required
                  />
                </div>
              </div>
              <div className="InvListSubContent-container__subContainer__inputFiled">
                <div className="InvListSubContent-container__subContainer__inputFiled__label">
                  Description <div style={{ color: "red" }}> * </div>
                </div>
                <div className="InvListSubContent-container__subContainer__inputFiled__value">
                  <textarea
                    type="texts"
                    value={inventList.description}
                    cols="40"
                    rows="5"
                    // variant="outlined"
                    //placeholder="Enter Old Password"
                    className="InvListSubContent-container__subContainer__inputFiled__value__input"
                    onChange={(e) => {
                      handleUpdateItem(e, "description");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="InvListSubContent-container__subContainer__buttonContainer">
              <button
                className="InvListSubContent-container__subContainer__buttonContainer__submit2"
                onClick={handleInventoryProduct}
              >
                Submit
              </button>
            </div>
          </div>
          {item_image !== "" && (
            <div className="InvListSubContent-container__subContainer__uploadImage">
              <img
                src={item_image}
                alt="product"
                className="InvListSubContent-container__subContainer__uploadImage__display"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryForm;


